import { Link } from "react-router-dom";

export default function Privacy() {
  return (
    <div className="max-w-screen-xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
      <Link to="/" className="text-teal-500 hover:text-teal-400">
        Back to Home
      </Link>
      <h1 className="text-3xl font-extrabold text-gray-900 sm:text-3xl mt-8">
        Renpilot - Privacy Policy
      </h1>
      <div className="mt-4 mb-8 pr-12 text-gray-700 sm:text-xl/relaxed">
        <b>Effective Date: 8 July 2024</b>
        <br />
        <br />
        Babji Ltd ("we", "our", or "us") respects your privacy and is committed
        to protecting the personal information you share with us when you use
        our mobile application RenPilot ("the App"). This Privacy Policy
        outlines how we collect, use, disclose, and safeguard your information
        when you use our App.
        <br />
        <br />
        <ol className="list-decimal list-inside">
          <li>
            <b>Information We Collect:</b> We may collect and process the
            following data about you:
            <br />
            <b>Personal Information:</b>
            <ul className="list-disc list-inside">
              <li>Name</li>
              <li>Email address</li>
              <li>User IDs</li>
              <li>Address</li>
              <li>Phone number</li>
            </ul>
            <b>Project Data:</b>
            <ul className="list-disc list-inside">
              <li>
                Details related to your renovation projects including photos,
                videos, files, and documents.
              </li>
            </ul>
            <b>Financial Information:</b>
            <ul className="list-disc list-inside">
              Expenses and budgeting details related to your renovation
              projects.
            </ul>
            <b>Client Information:</b>
            <ul className="list-disc list-inside">
              <li>
                Details about clients, including communications and project
                history.
              </li>
            </ul>
          </li>
          <br />
          <br />
          <li>
            <b>How We Use Your Information:</b> We use the information we
            collect in the following ways: To provide and maintain our App and
            its features. To manage and organize your renovation projects
            efficiently. To communicate with you, including responding to your
            inquiries, requests, and providing support. To improve our App and
            develop new features. To generate reports and analytics regarding
            usage of the App.
          </li>
          <br />
          <br />
          <li>
            <b>Sharing Your Information:</b> We do not sell, trade, or otherwise
            transfer your personal information to outside parties. We may share
            your information with trusted third parties who assist us in
            operating our App, conducting our business, or servicing you,
            provided that such parties agree to keep this information
            confidential.
          </li>
          <br />
          <br />
          <li>
            <b>Security of Your Information:</b> We implement a variety of
            security measures to maintain the safety of your personal
            information when you use our App. However, no method of transmission
            over the internet or electronic storage is completely secure, so we
            cannot guarantee absolute security.
          </li>
          <br />
          <br />
          <li>
            <b>Your Choices:</b> You can choose not to provide certain
            information, but that may limit your ability to use certain features
            of the App. You may also review and update your personal information
            by contacting us.
          </li>
          <br />
          <br />
          <li>
            <b>Children&apos;s Privacy:</b> Our App is not intended for use by
            children under the age of 18. We do not knowingly collect personal
            information from children under 18. If we learn that we have
            collected personal information from a child under 18, we will delete
            that information promptly.
          </li>
          <br />
          <br />
          <li>
            <b>Changes to This Privacy Policy:</b> We may update our Privacy
            Policy from time to time. We will notify you of any changes by
            posting the new Privacy Policy on this page and updating the
            effective date above. You are advised to review this Privacy Policy
            periodically for any changes.
          </li>
          <br />
          <br />
          <li>
            <b>Contact Us:</b> If you have any questions about this Privacy
            Policy, please contact us at:{" "}
            <a href="mailto:connect@withbabji.com">connect@withbabji.com</a>
          </li>
        </ol>
        <br />
        <b>
          By using the Renpilot Android or iOS App, you agree to the collection
          and use of information in accordance with this Privacy Policy.
        </b>
      </div>
    </div>
  );
}
