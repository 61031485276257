import { Link } from "react-router-dom";

export default function Terms() {
  return (
    <div className="max-w-screen-xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
      <Link to="/" className="text-teal-500 hover:text-teal-400">
        Back to Home
      </Link>
      <h1 className="text-3xl font-extrabold text-gray-900 sm:text-3xl mt-8">
        Renpilot - Terms &amp; Conditions
      </h1>
      <p className="mt-4 mb-8 pr-12 text-gray-700 sm:text-xl/relaxed">
        <div className="mt-4 mb-8 pr-12 text-gray-700 sm:text-xl/relaxed">
          <b>Effective Date: 8 July 2024</b>
          <br />
          <br />
          <p>
            Welcome to RenPilot, an application operated by Babji Ltd ("we",
            "our", or "us"). By downloading or using our App, you agree to
            comply with and be bound by these Terms & Conditions ("Terms").
            Please read these Terms carefully. If you do not agree with these
            Terms, you should not use our App.
          </p>
          <br />
          <ol className="list-decimal list-inside">
            <li>
              <b>Use of the App: </b>
              RenPilot is intended to assist in managing property renovation
              projects. You agree to use the App only for its intended purposes
              and in accordance with these Terms and all applicable laws and
              regulations.
            </li>
            <br />
            <li>
              <b>User Accounts</b>
              To access certain features of the App, you may be required to
              create an account. You agree to provide accurate, current, and
              complete information during the registration process and to update
              such information to keep it accurate, current, and complete. You
              are responsible for safeguarding your password and for any
              activities or actions under your account.
            </li>
            <br />
            <li>
              <b>Privacy: </b>
              Your use of the App is also governed by our Privacy Policy, which
              can be found{" "}
              <Link to="/privacy" className="text-teal-600">
                here
              </Link>
              . Please review our Privacy Policy to understand our practices.
            </li>
            <br />
            <li>
              <b>Intellectual Property: </b>
              All content included on the App, such as text, graphics, logos,
              images, and software, is the property of Babji Ltd or its content
              suppliers and protected by copyright and other intellectual
              property laws. You may not use, reproduce, distribute, or create
              derivative works of such content without our express written
              permission.
            </li>
            <br />
            <li>
              <b>Prohibited Uses: </b>You agree not to use the App to:
              <ul className="list-disc list-inside">
                <li>Violate any applicable laws or regulations.</li>
                <li>Infringe on the intellectual property rights of others.</li>
                <li>Upload or transmit viruses or other harmful code.</li>
                <li>
                  Engage in any activity that could damage or interfere with the
                  functioning of the App.
                </li>
              </ul>
            </li>
            <br />
            <li>
              <b>Termination: </b>
              We may terminate or suspend your access to the App, without prior
              notice or liability, for any reason, including if you breach these
              Terms. Upon termination, your right to use the App will
              immediately cease.
            </li>
            <br />
            <li>
              <b>Limitation of Liability: </b>
              To the fullest extent permitted by law, Babji Ltd shall not be
              liable for any indirect, incidental, special, consequential, or
              punitive damages, or any loss of profits or revenues, whether
              incurred directly or indirectly, or any loss of data, use,
              goodwill, or other intangible losses, resulting from
              <ol className="list-decimal list-inside">
                <li>your use or inability to use the App</li>
                <li>
                  any unauthorized access to or use of our servers and/or any
                  personal information stored therein
                </li>
                <li>
                  any interruption or cessation of transmission to or from the
                  App.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <b>Changes to the Terms: </b>
              We reserve the right to modify these Terms at any time. We will
              notify you of any changes by posting the new Terms on this page.
              You are advised to review these Terms periodically for any
              changes. Changes to these Terms are effective when they are posted
              on this page.
            </li>
            <br />
            <li>
              <b>Indemnification: </b>
              You agree to indemnify and hold Babji Ltd harmless from any claim
              or demand, including reasonable attorneys&apos; fees, made by any
              third party due to or arising out of your use of the App, your
              violation of these Terms, or your violation of any rights of
              another.
            </li>
            <br />
            <li>
              <b>Governing Law: </b>
              These Terms shall be governed and construed in accordance with the
              laws of the United Kingdom, without regard to its conflict of law
              provisions.
            </li>
            <br />
            <li>
              <b>Entire Agreement: </b>
              These Terms constitute the entire agreement between you and Babji
              Ltd regarding the use of the App and supersede any prior
              agreements between you and us.
            </li>
            <br />
            <li>
              <b>Contact Us: </b>
              If you have any questions about these Terms, please contact us at:{" "}
              <a href="mailto:connect@withbabji.com" className="text-teal-600">
                connect@withbabji.com
              </a>
            </li>
          </ol>

          <br />
          <b>
            By using the Renpilot Android or iOS App, you agree to be bound by
            these Terms & Conditions.
          </b>
        </div>
      </p>
    </div>
  );
}
