import { Link } from "react-router-dom";

export default function About() {
  return (
    <div>
      <section className="min-h-screen relative bg-[url(https://images.unsplash.com/photo-1604014237800-1c9102c219da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80)] bg-cover bg-center bg-no-repeat">
        <div className="absolute inset-0 bg-gray-900/75 sm:bg-transparent sm:from-gray-900/95 sm:to-gray-900/25 sm:bg-gradient-to-r"></div>
        <div className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8">
          <div className="max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
            <h1 className="text-3xl font-extrabold text-white sm:text-5xl">
              <strong className="block font-extrabold text-teal-500 text-6xl">
                Renpilot
              </strong>
            </h1>

            <p className="mt-4 max-w-lg text-white sm:text-4xl/relaxed">
              Transform the way you manage &amp; renovate properties.
            </p>
          </div>
        </div>
      </section>
      <footer className="bg-gray-900 fixed inset-x-0 bottom-0">
        <div className="max-w-screen-xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
          <div className="flex flex-col items-center justify-center sm:flex-row sm:justify-between">
            <p className="text-sm text-gray-400 sm:text-base">
              &copy; {new Date().getFullYear()} Renpilot. All rights reserved.{" "}
              <a
                href="mailto:connect@withbabji.com"
                className="text-teal-500 hover:text-teal-400"
              >
                Contact Us
              </a>
              {" / "}
              <Link to="/privacy" className="text-teal-500 hover:text-teal-400">
                Privacy Policy
              </Link>
              {" / "}
              <Link to="/terms" className="text-teal-500 hover:text-teal-400">
                Terms &amp; Conditions
              </Link>
            </p>
            <p className="mt-4 text-sm text-gray-400 sm:mt-0">
              Developed by{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://withbabji.com"
                className="text-teal-500 hover:text-teal-400"
              >
                Babji Ltd
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
